import React from 'react'

const Testimonials = () => {
  return (
    <div>
        <h2 className='text-center uppercase font-semibold text-[36px] sm:text-[50px] py-[1em] pb-[1.5em]'>Testimonials</h2>



        <div className='w-[80%] md:w-[70%]  max-w-[2000px] mx-auto bg-white py-[2em] px-[2em] md:px-[4em] rounded-[20px] testimonial'>
        <p className='text-[16px] sm:text-[18px] md:text-[26px] text-left'>“Laksh is a talented developer who has a keen eye for design to compliment his technical skills”</p>
        <p className='text-[16px] sm:text-[18px] md:text-[26px] pt-[1em] text-right font-medium'>Matthew James</p>
        <p className='text-[12px] sm:text-[14px] md:text-[20px] text-right font-light'>Lead Developer, Primitive</p>

        </div>

    </div>
  )
}

export default Testimonials
