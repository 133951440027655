import React from 'react'

const Experience = () => {
  return (
    <div className='relative  w-mobile md:w-[60%]  max-w-[2000px] mx-auto pb-[3em]'>
                <h2 className='text-center uppercase font-semibold text-[36px] sm:text-[50px] pt-[1.5em] pb-[2em]'>My Experience</h2>

      <div className="timeline relative">
  
  <div className="container ">
    <div className="content">
      <h2 className='timelinedate left-[-110px] sm:left-[-150px] lg:left-[-200px]'>May 2024</h2>
      <p className='text-[16px] sm:text-[30px] md:text-[40px] lg:text-[50px] font-medium'>Web Developer Intern</p>
      <p className='text-[16px] sm:text-[24px] md:text-[30px] lg:text-[40px]'>Primitive</p>
    </div>
  </div>
  <div className="container mt-[3em]">
    <div className="content">
      <h2 className='timelinedate left-[-110px] sm:left-[-150px] lg:left-[-200px]'>Jan 2024</h2>
      <p className='text-[16px] sm:text-[30px] md:text-[40px] lg:text-[50px] font-medium'>Undergraduate Research Assistant</p>
      <p className='text-[16px] sm:text-[24px] md:text-[30px] lg:text-[40px]'>Texas Tech University </p>
    </div>
  </div>
  <div className="container mt-[3em]">
    <div className="content">
      <h2 className='timelinedate left-[-110px] sm:left-[-150px] lg:left-[-200px]'>Mar 2023</h2>
      <p className='text-[16px] sm:text-[30px] md:text-[40px] lg:text-[50px] font-medium'>Web Developer</p>
      <p className='text-[16px] sm:text-[24px] md:text-[30px] lg:text-[40px]'>Robotics for All, Inc</p>
    </div>
  </div>
  <div className="container mt-[3em]">
    <div className="content">
      <h2 className='timelinedate left-[-110px] sm:left-[-150px] lg:left-[-200px]'>Jul 2006</h2>
      <p className='text-[16px] sm:text-[30px] md:text-[40px] lg:text-[50px] font-medium'>Birth</p>
    </div>
  </div>
  
</div>
    </div>
  )
}

export default Experience
