import React from 'react';
import Header from '../components/Header';
import HeroAbout from '../components/HeroAbout';
import Line from '../components/Line';
import Experience from '../components/Experience';
import Skill  from '../components/Skill';
import Footer from '../components/Footer';
import QuickFacts from '../components/QuickFacts';
import { useEffect } from 'react';
import PicsRibbon from '../components/PicsRibbon';


const About = () => {
  useEffect(() => {
    document.title = 'About Me | Lakshh.';
  }, []);
  return (
    <div>
      <Header />
      <HeroAbout />

      <Line />

      <Experience />
      <Line />
      <Skill />
      <Line />
      <QuickFacts />
      <Footer/>
    </div>
  )
}

export default About
