import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const HeroAbout = () => {
  return (
    <div className='px-[10%] w-full overflow-x-hidden text-left text-txt pt-[10vh] relative h-[65vh] sm:min-h-[550px]'>
             <div className="absolute w-full bottom-0">
             <img src="/avatar_3.png" className="relative w-[30%] sm:w-[22%] md:w-[18%] lg:w-[15%] xl:w-[11%] h-1/2 ml-[50%] sm:ml-[55%] md:ml-[60%] lg:ml-[65%]" />
             </div>
        <div className='absolute'>
        <h1 className="">About Me</h1>
        <h3 className='py-[1em] '>I am a Developer based in the Lone Star State</h3>
        <p className='text-[12px] md:text-[18px] 2xl:text-[24px] font-light  max-w-[50%]'>Since 2017, I’ve been on a journey as a Developer and Designer, dedicated to developing user-friendly applications and creating robust systems. My experience has taught me the importance of perspective, and I strive to deliver results that resonate with my audience. Let’s connect and create something extraordinary together!</p>
        <p className='text-[11px] md:text-[16px] 2xl:text-[22px] font-light  max-w-[50%] pt-[0.7em] underline'><Link to="/MyStory">Click Here to read full text</Link></p>
        </div>
    </div>
  )
}

export default HeroAbout
