import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const Skill = () => {
  const labels = ["Front-end", "Back-end", "Database", "GenAI", "Yapping about Formula 1"];
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: "#FF4A4A",
        borderColor: "rgb(255, 99, 132)",
        data: [3.25, 2.75, 2.5, 3, 4],
      },
    ],
  };

  const options = {
    aspectRatio: function(context) {
      if (window.innerWidth < 640) {
        return  1/1; // return font size for screens smaller than 768px
      } else {
        return 1.2/1; // return font size for screens larger than or equal to 768px
      }
    },
  
    plugins: {
        legend: {
            display: false
        },    
        tooltip: {
          enabled: false
        },
    },



    scales: {
      x: {
        ticks: {
          font: {
            family: "Arial",
            weight: "600",
            color: "#666",
            size: function(context) {
              if (window.innerWidth < 400) {
                return 8; // return font size for screens smaller than 768px
              } else {
                return 16; // return font size for screens larger than or equal to 768px
              }
            },
          },
          minRotation: 90,
        },
      },
      y: {
        ticks: {
          callback: function(value) {
            if (value === 1) return "";
            if (value === 2) return "Amatuer";
            if (value === 3) return "Advanced";
            if (value === 4) return "GOAT";
            return "";
          },
          font: {
            size: function(context) {
              if (window.innerWidth < 400) {
                return 8; // return font size for screens smaller than 768px
              } else {
                return 14; // return font size for screens larger than or equal to 768px
              }
            },
            family: "Arial",
            weight: "700",
            color: "#000",
          },
        },
      },
    },

  };


  
  return (
    <div className="w-[90%] md:w-[70%] lg:w-[60%]  max-w-[2000px] mx-auto" >
            <h2 className='text-center uppercase font-semibold text-[36px] sm:text-[50px] py-[1em] sm:py-[1.5em]'>My skills</h2>
      <div className="w-full pr-[10vw] sm:pr-0">
        <Bar data={data} options={options} style={{width: 100}}/>
      </div>
      <div className="mt-[4em] flex text-left w-full justify-evenly  text-[#545454] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px]">
        <ul className="">
          <li className="pb-[0.9em] font-semibold text-[16px] sm:text-[18px] md:text-[22px] lg:text-[22px]">Frontend:</li>
          <li className="py-[0.5em]">TalwindCSS</li>
          <li className="py-[0.5em]">JavaScript</li>
          <li className="py-[0.5em]">React.JS</li>
          <li className="py-[0.5em]">Vue</li>
        </ul>
        <ul className="">
          <li className="pb-[0.9em] font-semibold text-[16px] sm:text-[18px] md:text-[22px] lg:text-[22px]">Backend:</li>
          <li className="py-[0.5em]">Python</li>
          <li className="py-[0.5em]">C</li>
          <li className="py-[0.5em]">NodeJS</li>
          <li className="py-[0.5em]">Django</li>
          <li className="py-[0.5em]">Laravel</li>
        </ul>
        <ul className="">
          <li className="pb-[0.9em] font-semibold text-[16px] sm:text-[18px] md:text-[22px] lg:text-[22px]">Database:</li>
          <li className="py-[0.5em]">MongoDB</li>
          <li className="py-[0.5em]">SQL</li>
          <li className="py-[0.5em]">Chroma</li>
        </ul>
        <ul className="">
          <li className="pb-[0.9em] font-semibold text-[16px] sm:text-[18px] md:text-[22px] lg:text-[22px]">GenAI:</li>
          <li className="py-[0.5em]">TensorFlow</li>
          <li className="py-[0.5em]">Hugging Face</li>
        </ul>
      </div>
    </div>
  );
};

export default Skill;