import React from 'react';
import Header from '../components/Header';
import HeroBlog from '../components/HeroBlog';
import Line from  '../components/Line';
import Footer from '../components/Footer';
import { useEffect } from 'react';

import Blogcontainer from '../components/Blogcontainer';
const Blog = () => {
  useEffect(() => {
    document.title = 'Blog | Lakshh.';
  }, []);
  return (
    <div>
      
      <Header/>
      <HeroBlog />
      <Line />
      <Blogcontainer />
      <Footer />
    </div>
  )
}

export default Blog
