import React from 'react'

const HeroWork = () => {
  return (
    <div className='px-[10%] w-full overflow-x-hidden text-left text-txt pt-[10vh] relative h-[65vh] sm:min-h-[500px]'>
    <div className="absolute w-full bottom-0">
    <img src="/avatar_3.png" className="relative w-[30%] sm:w-[22%] md:w-[18%] lg:w-[15%] xl:w-[11%] h-1/2 ml-[50%] sm:ml-[55%] md:ml-[60%] lg:ml-[65%]" />
    </div>
<div className='absolute'>
<h1 className="">My Work</h1>
<h3 className='pt-[1em] pb-[0.5em]'>All the freaky stuff I do</h3>
<p className='text-[12px] md:text-[18px] 2xl:text-[24px]  max-w-[50%] font-light'>I’ve always enjoyed working on a variety projects ranging from web apps to AI projects. Whether it’s tackling a challenge with a fresh approach or experimenting with something totally outside the box, I love making tech that’s both fun and functional. I believe every project tells a story, and I’m excited to share what I’ve been up to along the way!</p>
</div>
</div>
  )
}

export default HeroWork
