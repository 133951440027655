import React, { useState, useEffect } from 'react';

const PicsRibbon = () => {
  const images = [
    { src: "/1.jpeg" },
    { src: "/6.jpeg" },

    { src: "/3.jpeg" },
    { src: "/4.jpeg" },

    { src: "/5.jpeg" },

  ];
  const delayValues = [0, 50 ,100, 150, 200];
  const [loaded, setLoaded] = useState({});

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        setLoaded((prevLoaded) => ({ ...prevLoaded, [image.src]: true }));
      };
    });
  }, []);

  return (
    <div className='w-full'>
      <div className='flex  justify-end w-[80%] md:w-[60%]  max-w-[2000px] mx-auto  py-[2em]'>
        <div className='text-right w-[90%]'>
        <p className='text-[22px] sm:text-[24px] md:text-[26px] lg:text-[36px] font-semibold' data-aos="swipe-left">I have a life outside of coding too</p>
        <h2 className='pl-[4em] text-[12px] sm:text-[14px] md:text-[18px] lg:text-[20px] font-regular'>When I am not coding, I like to spend my time reading, hanging out with friends, and with everything related to Formula one.</h2>
        </div>

      </div>
      <div className='relative  w-[80%] md:w-[60%]  max-w-[2000px] mx-auto grid grid-cols-2 lg:grid-cols-5 gap-[0.5vw]'>
        {images.map((image, index) => (
          <div key={index} data-aos="fade-right" data-aos-delay={delayValues[index]}  data-aos-once="true" className={`relative aspect-square w-full  p-[1px] md:p-[3px] bg-white rounded-[2px] sm:rounded-[3px] pics ${index >= 4 ? 'hidden lg:block' : ''}`}>
   
              <div className='relative'>
                <div className={`loader absolute top-0 left-0 w-full h-full flex justify-center items-center ${loaded[image.src] ? 'hidden' : ''}`}>
                  <div className="loader-inner"></div>
                </div>
                <img src={image.src} className={` rounded-[1px] sm:rounded-[2px] w-full h-full object-cover ${loaded[image.src] ? 'loaded' : 'loading'}`} />
              </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PicsRibbon