import Header from '../components/Header';
import { useEffect } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import PicsRibbon from '../components/PicsRibbon';


const MyStory = () => {
  useEffect(() => {
    document.title = 'My Story | Lakshh.';
  }, []);
  return (
    <div>
              <Header />
    
        <div className='relative  w-[80%] md:w-[60%]  max-w-[2000px] mx-auto'>
        <h2 className='text-center uppercase font-semibold text-[36px] sm:text-[50px] pt-[1em] sm:pt-[1.5em]'>My story</h2>
        <p className='text-center text-[24px] sm:text-[30px] leading-[]'>How I got into coding</p>
        <p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>"Boys, no coding today—we’re playing Counterstrike!" said the instructor on the last day of my coding classes. It was the summer of 2017, and life was pretty chill. No big worries, just a bunch of people trying to learn to code and have some fun. I was learning C++ (yeah, C++!  What kind of an idiot starts with C++?) It was tough and confusing, but I kept at it, somehow managing to write a few lines of code that didn’t completely blow up my computer.</p>
<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>C++ wasn’t exactly love at first sight. I guess it wasn’t even a sight. It felt more like tripping over a bunch of brackets and semicolons. But as stubborn as I was, I kept going. Then, 2018 rolled around, and I decided to try something new and started learning Python with some YouTube tutorials and random websites. I made a bunch of prediction models using basic libraries like NumPy and Pandas. It was fun to see the results on the screen, but it never really clicked for me. Funny enough, I never imagined a day would come when companies would start letting people go because they knew python (Yes, I am talking about Google). Tech changes fast, I guess.</p>
<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>
Then came 2020—*the year of the apocalypse*. COVID hit, and I had nothing to do except eat all day (honestly, I kinda miss that). Out of sheer boredom, I made a website following a YouTube tutorial. It was just a basic HTML, CSS, and JavaScript, but hey, It counts! It was way more fun than messing around with Python or building those silly prediction models I used to before. I kept experimenting with web development and ended up building some pretty cool stuff using PHP and SQL (I still don’t understand why PHP- I’m guess I’m Fernando Alonso when it comes to making choices – *if you got this reference, you have all my respect.) Then school came back - not just any school, but High School. Sucks that everyone has to go through it. During that, I was a “semi-professional” web developer at a student-run startup/org, Robotics For All, during 2023 for extra-curriculars to get into a college. I stayed there until September 2023, mainly because I genuinely enjoyed contributing to their mission and honing my skills. Also, by that time, I had got acceptance letters from a bunch of colleges.</p>
<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>
College was easy for me; maybe that’s just the “Asian kid” effect or perhaps because where I’m from, high schools are tougher than what it feels like to wake up for an 8 AM class now. In my first semester, I made up my mind to take web development seriously. I knew React and other frameworks before (thanks to Robotics For All), but this time, I tried to master them.</p>
<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>
By the second semester, I got hired by a professor to work in an AI research. I got my hands dirty with all the fancy terms: RAG, embeddings, AGI… you name it. I spent my time building models, fine-tuning algorithms, and analyzing datasets to extract meaningful insights. But honestly, it wasn’t just about the buzzwords; it was the supportive team I had around me, which made the experience all the more rewarding.</p>
<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>
Then came the summer of 2024, and I had already landed an internship at Primitive as a software developer - all thanks to the web projects I’d worked on earlier. But guess what? I ended up using PHP again, except it was Laravel instead of actual PHP. After all these years, I somehow circled back to it. I worked on both the front-end and sometimes the back-end of client websites like Mojave, CTSI, and West Texas Heart. I truly loved the job, and I have to say, my team was hands down the most fun team to work with!</p>

<p className='text-[14px] md:text-[22px] pt-[1em] text-justify'>
So, here I am - just a guy who stumbled his way into coding, tried a bit of everything, made some crazy choices, and had a blast along the way. Over these years, I have learned the importance of teams and a good workspace. I’ve been super lucky with my teammates and coworkers, and I genuinely can’t be grateful enough for that.</p>


</div>
<PicsRibbon />

<p className='relative  w-[80%] md:w-[60%]  max-w-[2000px] mx-auto text-[11px] md:text-[16px] 2xl:text-[22px] font-light text-left pt-[2em] underline pt-[4em]'><Link to="/About">Click Here to go back to the About page</Link></p>
<Footer />      
    </div>
  )
}

export default MyStory
