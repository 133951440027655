import './App.css';
import FeaturedWork from './components/FeaturedWork';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroHompage from './components/HeroHompage';
import Line from './components/Line';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Work from './pages/Work'
import MyStory from  './pages/MyStory';
import Testimonials from './components/Testimonials';

function App() {

  
  return (
    <div className="App ">
  
      <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
                <Header />
      <HeroHompage />
      <Line />
      <FeaturedWork/>
      <Line />
      <Testimonials />
      <Footer />
    
          </>
        } />
        <Route path="/about" element={<About />} /> 
        <Route path="/work" element={<Work />} /> 
        <Route path="/blog" element={<Blog />} /> 
        <Route path="/contact" element={<Contact />} />
        <Route path="/mystory" element={<MyStory />} />


      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
