import React, { useEffect, useState } from 'react';


const HeroHompage = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[75vh] sm:h-[87vh] overflow-hidden sm:min-h-[700px]">
      <div
        className="absolute z-1 w-full h-full"
        style={{
          transform: `translateY(${offset * 0.4}px)`,
        }}
      >
        <div className="flex px-[5%] md:px-[10%] relative top-[15vh] lg:top-[13vh] justify-between">
          <div className="text-left mt-[4em] md:mt-[8em] w-[25%]">
            <h3 className=" text-txt">&lt;Developer/&gt;</h3>
            <p className="text-[10px] lg:text-[15px] text-grey">
            /dɪˈvɛləpər/<br></br>noun<br></br>A professional who writes code and builds software applications, working on everything from web development to software engineering and systems programming.
            </p>
          </div>
          <div className="text-left justify-center relative">
            <h2 className="text-[16px] sm:text-[18px] md:text-[26px] lg:text-[40px]" data-aos-delay="100">HEY, I'M</h2>
            <h1 className="text-[70px] sm:text-[90px] md:text-[120px] lg:text-[160px] leading-[0.75em] font-league ">LAKSHH.</h1>
          


          </div>
          <div className="text-left mt-[4em] md:mt-[8em] w-[25%]">
            <h3 className=" text-txt">Designer</h3>
            <p className="text-[10px] lg:text-[15px] text-grey">
              /dəˈzīnər/<br></br>noun<br></br>A person focuses on the user experience and visual aspects of applications, creating layouts, interfaces, and overall aesthetics to ensure usability and functionality.
            </p>
          </div>
        </div>
      </div>
      <div className="absolute z-1 w-full bottom-0">
        <img src="/avatar_1.png" className="w-[28%] md:w-[20%] lg:w-[14%] mx-auto"/>
      </div>
    </div>
  );
};

export default HeroHompage;