import React from 'react'
import Header from '../components/Header'
import HeroWork from '../components/HeroWork'
import Line from '../components/Line'
import Projects from '../components/Projects'
import Footer from  '../components/Footer'
import { useEffect } from 'react';

const Work = () => {
  useEffect(() => {
    document.title = 'Work | Lakshh.';
  }, []);
  return (

    <div>
      <Header />
      <HeroWork />
      <Line />
      <Projects />
      <Footer />
    </div>
  )
}

export default Work
