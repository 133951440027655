import React, { useState, useEffect } from 'react';

const projectslist = [
  {
    name: "M-16",
    link: "https://github.com/Project-M4/Methane-Tracker-fullstack", 
    imagePath: "./M-16.jpeg",
    skills: ["React", "Django", "SQLite", "Google Cloud Console", "TailwindCSS"]
  },
  {
    name: "CTSI",
    link: "https://dev-laksh-ctsi.myprimitive.cloud/", 
    imagePath: "./CTSI.jpeg",
    skills: ["Laravel", "Vue.js", "Tailwind"]
  },
  {
    name: "Mojave",
    link: "https://dev-laksh-mojave.myprimitive.cloud/", 
    imagePath: "./Mojave.jpeg",
    skills: ["Laravel", "Vue.js", "Tailwind", "Google analytics"]
  },
  {
    name: "West Texas Heart",
    link: "https://dev-laksh-west-texas-heart.myprimitive.cloud/", 
    imagePath: "./West Texas Heart.jpeg",
    skills: ["Laravel", "Vue.js", "Tailwind"]
  },

  {
    name: "OnRivr",
    link: "https://github.com/PrimitiveSocial/prim-dev-laksh", 
    imagePath: "./OnRivr.jpeg",
    skills: ["Laravel", "Vue.js", "Tailwind"]
  },
  {
    name: "Redzone",
    link: "https://lakshkumar06.github.io/redzone.com/", 
    imagePath: "./Redzone.jpeg",
    skills: ["HTML", "CSS", "JavaScript"]
  },
  {
    name: "College 101",
    link: "https://github.com/lakshkumar06/College101", 
    imagePath: "./College 101.jpeg",
    skills: ["HTML", "CSS", "JavaScript"]
  },
  {
    name: "Drink Prime",
    link: "https://lakshkumar06.github.io/drinkprime/", 
    imagePath: "./Drink Prime.jpeg",
    skills: ["HTML", "CSS", "JavaScript"]
  },
]

const Projects = () => {
  const [loaded, setLoaded] = useState({});

  useEffect(() => {
    projectslist.forEach((project) => {
      const img = new Image();
      img.src = project.imagePath;
      img.onload = () => {
        setLoaded((prevLoaded) => ({ ...prevLoaded, [project.name]: true }));
      };
    });
  }, []);

  return (
    <div className="relative">
      <div className='px-[10vw] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[2.5vw] gap-y-[2.5vw] text-[#000]'>
        {projectslist.map((project, index) => (
          <div key={index} className="bg-white gridBox border-[0.5px] border-[#9e9e9e] relative">
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              <div className='relative'>
                <div className={`loader absolute top-0 left-0 w-full h-full flex justify-center items-center ${loaded[project.name] ? 'hidden' : ''}`}>
                  <div className="loader-inner"></div>
                </div>
                <img src={project.imagePath} className={`w-[80vw] h-[48.5vw] md:w-[38.75vw] md:h-[23.73vw] lg:w-[25vw] lg:h-[15.4vw] border-b-[0.5px] border-[#9e9e9e] ${loaded[project.name] ? 'loaded' : 'loading'}`} />
                <div className="imageOverlay top-0 left-0 w-full h-full bg-black bg-opacity-50 ">
                  <div className="h-full flexCol">
                    <div className="">
                      {project.skills.map((skill, index) => (
                        <div key={index} className=" inline-block text-white text-[14px] sm:text-[18px] lg:text-[20px]  mr-[7%] mb-[7%] sm:mr-[5%] sm:mb-[5%] py-[0.2em] px-[1em] sm:py-[0em] border-[0.5px] border-white rounded-[1.5em] bg-white bg-opacity-[0.1] ">{skill}</div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='relative'>
                <h3 className="px-[1em] py-[0.7em] text-left text-[14px] sm:text-[17px] md:text-[20px] lg:text-[22px] font-medium border-t-[0.5px] border-[#9e9e9e]">{project.name}</h3>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Projects