import React from 'react';
import Header from '../components/Header';
import HeroContact from  '../components/HeroContact';
import Line from '../components/Line';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    document.title = 'Contact | Lakshh.';
  }, []);
  return (
    <div>
      <Header/>
      <HeroContact />
      <Line />
      <ContactForm/>
      <Footer />
    </div>
  )
}

export default Contact
