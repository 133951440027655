import React from 'react'

const QuickFacts = () => {
  const facts = [
    { title: 'Home town', value: 'Karachi, Pakistan' },
    { title: 'Education', value: 'Bachelor of Science in Computer Science at Texas Tech University, Class of ‘27. Not to mention the tons of YouTubers who made me question if college was even worth it.' },
    { title: 'Hobbies', value: 'History books, Cars, and delulu' },
    { title: 'Favorite Book', value: 'Dark Persuasion by Joel E. Dimsdale' },
    { title: 'Coding playlist', value: 'Depends on the amount of caffiene and how hard the code is; its a spectrum from Cigarettes After Sex to Rammstein' },
    { title: 'Dream Travel Spot', value: 'Stuttgart, Germany - For Porsche Museum ofcourse' },


  ];

  return (
    <div className='relative  w-[80%] md:w-[60%]  max-w-[2000px] mx-auto'>
      <h2 className='text-center uppercase font-semibold text-[36px] sm:text-[50px] py-[1em] sm:py-[1.5em]'>Some Quick Facts</h2>
      <div className='text-left'>
        {facts.map((fact, index) => (
          <div key={index}>
            <p className='text-[18px] md:text-[26px] font-semibold pt-[1em]'>{fact.title}</p>
            <p className='text-[14px] md:text-[22px]'>{fact.value}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuickFacts