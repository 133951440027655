import React from 'react'

const Line = () => {
  return (
    <div>
      <hr 
        className='my-[30px] sm:my-[50px] h-[1px] border-[0] relative' 
        style={{
          background: `linear-gradient(
            to right, 
            rgba(225, 225, 225, 0) 0%, 
            rgba(187, 187, 187, 1) 25%, 
            rgba(187, 187, 187, 1) 75%, 
            rgba(187, 187, 187, 0) 100%
          )`
        }} 
      ></hr>
    </div>
  )
}

export default Line