import React from 'react'

const Blogcontainer = () => {
  return (
    
    <div className='px-[10vw] text-left py-[3em]'>
       <h2 className='text-[24px] md:text-[36px] lg:text-[48px]'>Nothing here for now </h2>
    </div>
  )
}

export default Blogcontainer
