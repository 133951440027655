import React from 'react'

const HeroBlog = () => {
  return (
    <div>
          <div className='px-[10%] w-full text-left  overflow-x-hidden  text-txt pt-[10vh] relative h-[60vh] sm:min-h-[400px]'>
    <div className="absolute w-full bottom-0">
    <img src="/avatar_1.png" className="relative w-1/3 md:w-[20%] lg:w-[11%]  ml-[50%] md:ml-[66%]" />
    </div>
<div className='absolute'>
<h1 className="">Blog</h1>
<h3 className='pt-[1em] pb-[0.5em]'>I talk some deep shit sometimes. Stay connected</h3>

</div>
</div>
    </div>
  )
}

export default HeroBlog
