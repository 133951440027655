import React from 'react'

const ContactForm = () => {
  return (
    <div className='px-[10%]'>
        <h3 className='pt-[1em] pb-[1em] text-left text-[18px] md:text-[22px] lg:text-[30px]'>Or shoot an email</h3>
        <form action="https://api.web3forms.com/submit" method="POST" className='text-left text-[18px]'>
        <input type="hidden" name="access_key" value="dab1b545-421f-4252-9eb7-b54e2714d2e2"></input>

            <div className='sm:flex gap-[2em]'>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="name">Name</label><br/>
                    <input type='text' id='name' name='name' className='border-[1px] border-[#777777] px-[1em] py-[0.7em] w-full' required></input>
                </div>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="email">Email</label><br/>
                    <input type='email' id='email' name='email' className='border-[1px] border-[#777777] px-[1em] py-[0.7em] w-full' required></input>
                </div>
            </div>
            <div className='text-left full py-[1em]'>
                <label for="name">Message</label><br/>
                <textarea id='name' name='message' rows='5' className='border-[1px] border-[#777777] px-[1em] py-[0.7em] w-full box-border' required></textarea>
            </div>

            <div className='flex justify-center sm:justify-end'>
                <button type='submit' className='submitBtn text-white bg-secondary_background text-[24px] px-[5em] py-[0.2em] border-[2px] border-secondary_background'>Shoot</button>
            </div>
        </form>


      
    </div>
  )
}

export default ContactForm
